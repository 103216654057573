:host {
  display: inline-block;

  width: 1em;
  height: 1em;

  contain: strict;

  fill: currentColor;

  box-sizing: content-box !important;

}

:host .ionicon {
  stroke: currentColor;
}

.ionicon-fill-none {
  fill: none;
}

.ionicon-stroke-width {
  stroke-width: 32px;
  stroke-width: var(--ionicon-stroke-width, 32px);
}

.icon-inner,
.ionicon,
svg {
  display: block;

  height: 100%;
  width: 100%;
}

/* Icon RTL
 * -----------------------------------------------------------
 */

/**
 * Safari <16.4 incorrectly reports
 * that it supports :dir(rtl) when it does not.
 * This @supports statement lets us target
 * WebKit browsers to apply the RTL fallback.
 * -webkit-named-image only exists on WebKit.
 * For WebKit browsers that do support :dir(rtl)
 * (i.e. Safari >= 16.4) then the :dir(rtl)
 * code farther down on the page will take
 * effect and override this fallback.
 */
@supports (background: -webkit-named-image(i)) {
  :host(.icon-rtl) .icon-inner {
    transform: scaleX(-1);
  }
}

/**
 * Fallback for browsers that support
 * neither :host-context nor :dir.
 * The icon will not react to dir
 * changes, but it will at least
 * respect the dir on component load.
 */
@supports not selector(:dir(rtl)) and selector(:host-context([dir='rtl'])) {
  :host(.icon-rtl) .icon-inner {
    transform: scaleX(-1);
  }
}

/* :host-context is supported in chromium; :dir is supported in safari & firefox */
:host(.flip-rtl):host-context([dir='rtl']) .icon-inner {
  transform: scaleX(-1);
}

@supports selector(:dir(rtl)) {
  :host(.flip-rtl:dir(rtl)) .icon-inner {
    transform: scaleX(-1);
  }
  /**
   * This is needed for WebKit otherwise the fallback
   * will always cause the icon to be flipped if the document
   * loads in RTL.
   */
  :host(.flip-rtl:dir(ltr)) .icon-inner {
    transform: scaleX(1);
  }
}

/* Icon Sizes
 * -----------------------------------------------------------
 */

:host(.icon-small) {
  font-size: 1.125rem !important;
}

:host(.icon-large) {
  font-size: 2rem !important;
}

/* Icon Colors
 * -----------------------------------------------------------
 */

:host(.ion-color) {
  color: var(--ion-color-base) !important;
}

:host(.ion-color-primary) {
  --ion-color-base: var(--ion-color-primary, #3880ff);
}

:host(.ion-color-secondary) {
  --ion-color-base: var(--ion-color-secondary, #0cd1e8);
}

:host(.ion-color-tertiary) {
  --ion-color-base: var(--ion-color-tertiary, #f4a942);
}

:host(.ion-color-success) {
  --ion-color-base: var(--ion-color-success, #10dc60);
}

:host(.ion-color-warning) {
  --ion-color-base: var(--ion-color-warning, #ffce00);
}

:host(.ion-color-danger) {
  --ion-color-base: var(--ion-color-danger, #f14141);
}

:host(.ion-color-light) {
  --ion-color-base: var(--ion-color-light, #f4f5f8);
}

:host(.ion-color-medium) {
  --ion-color-base: var(--ion-color-medium, #989aa2);
}

:host(.ion-color-dark) {
  --ion-color-base: var(--ion-color-dark, #222428);
}
